import axios from 'axios';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import { useMemo } from 'react';
import {
    Card,
    Table,
    TableHead,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    IconButton,
    Switch,
    FormControlLabel
} from '@material-ui/core';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getUserList, deleteUser } from '../../redux/slices/user';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import {
    UserListHead,
    UserListToolbar,
    UserMoreMenu,
    ClientesMenuActions
} from '../../components/_dashboard/user/list';

//////// actions
import getListaClientes from '../../actions/getListaClientes';
import todosUsuarios from '../../actions/clientes/todosUsuarios';
import iniciarSessaoWhatsapp from '../../actions/clientes/iniciarSessaoWhatsapp';
import fecharSessaoWhatsapp from '../../actions/clientes/fecharSessaoWhatsapp';
import { Icon } from '@iconify/react';

import useAuth from '../../hooks/useAuth';
import actionDeletarCacheRedis from 'src/actions/admin/actionDeletarCacheRedis';
import actionEditarCliente from 'src/actions/clientes/actionEditarCliente';
import actionListaServidores from 'src/actions/admin/actionListaServidores';

///// Acordions

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import AccordionSummary from '@mui/material/AccordionSummary';

///// Bloqueio de Cliente

import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import actionAdminActionCliente from 'src/actions/clientes/actionAdminActionCliente';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// ----------------------------------------------------------------------
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import criarUsuario from 'src/actions/criarUsuario';
import v2_actionImportarContatos from '../../actions/v2/clientes/v2_importarContatos';
import { v4 as uuidv4 } from 'uuid';
import { useDropzone } from 'react-dropzone';
import LoadingScreen from 'src/components/LoadingScreen';
import { Box, MenuItem, Select } from '@mui/material';
import v2_actionListarClientesHub from 'src/actions/v2/admin/v2_actionListarClientesHub';


const TABLE_HEAD = [{ id: 'nome', label: 'Clientes', alignRight: false }, { id: '' }];

const TABLE_BODY = [
    { id: 'nome', label: 'Nome', alignRight: false },
    { id: 'sessao', label: 'Sessão', alignRight: false },
    { id: 'pod', label: 'Pod', alignRight: false },

    { id: 'numero', label: 'Número', alignRight: false },
    { id: 'status', label: 'Sentinela', alignRight: false },
    { id: 'isBlock', label: 'Bloqueado', alignRight: false }, // Nova coluna
    { id: 'fase', label: 'Fase', alignRight: false }, // Nova coluna
    { id: '' }
];


// Estilo básico do dropzone
const dropzoneStyle = {
    borderWidth: '2px',
    borderColor: '#888',
    borderRadius: '10px',
    padding: '30px',
    textAlign: 'center',
    cursor: 'pointer',
    backgroundColor: '#f0f0f0',
    color: '#555'
};

const dropzoneActiveStyle = {
    borderColor: '#4CAF50',
    backgroundColor: '#e0ffe0',
    color: '#333'
};

const dropzoneSelectedStyle = {
    backgroundColor: '#28a745',
    borderColor: '#218838',
    color: '#ffffff'
};

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, pod) {
    let filteredArray = [];

    array.forEach((hub) => {
        const filteredItems = hub.items.filter((item) => {
            // Verifica se o nome corresponde ao query, se houver
            const matchesQuery = query
                ? item.nome.toLowerCase().includes(query.toLowerCase())
                : true;

            // Verifica se o pod corresponde, exceto se for "Todos"
            const matchesPod = pod !== 'Todos'
                ? String(item.pod) === String(pod) || item.isHub || String(item.cliente_pai_id) === String(pod)
                : true;

            return matchesQuery && matchesPod;
        });

        // Adiciona apenas os hubs com items que correspondem aos critérios
        if (filteredItems.length > 0) {
            filteredArray.push({
                ...hub,
                items: filteredItems, // Inclui apenas os items filtrados
            });
        }
    });

    return filteredArray;
}



export default function Canais() {
    const { themeStretch } = useSettings();
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('nome');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(200);
    const [listaClientes, setListaClientes] = useState([]);
    const [listaServidores, setListaServidores] = useState([]);
    const [pod, setPod] = useState('Todos');
    const [clientesPorHub, setClientesPorHub] = useState([]);
    const filteredUsers = applySortFilter(listaClientes, getComparator(order, orderBy), filterName, pod);
    const { user } = useAuth();
    const [open, setOpen] = useState(false);
    const [newUserName, setNewUserName] = useState('');
    const [newUser, setNewUser] = useState('');
    const [newUserNumber, setNewUserNumber] = useState('');
    const [openImportModal, setOpenImportModal] = useState(false);
    const [csvFile, setCsvFile] = useState(null);
    const [replaceContacts, setReplaceContacts] = useState(false);
    const [linkChats, setLinkChats] = useState(false);
    const [ignoreErrors, setIgnoreErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newUserServer, setNewUserServer] = useState(''); // Adicionar estado para o servidor


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    //criar usuario


    const getProvedor = (provedor) => {
        return provedor === 'all' ? 'zapchat' : provedor;
    };
    const handleCreateUser = async () => {
        try {
            const response = await criarUsuario({
                scope: {
                    nome: newUserName,
                    usuario: newUser,
                    numero: newUserNumber,
                    isMarketplace: false,
                    provedor: getProvedor(user.provedor),
                    clienteIds: [],
                    clientePaiId: null,
                    server: newUserServer,
                    isHub: false
                }
            });

            if (response.status === 200) {
                setOpen(false);
                toast.success('Usuário criado com sucesso!');
            }
        } catch (error) {
            toast.error('Erro ao criar usuário');
        }
    };

    const handleOpenImportModal = () => {
        setOpenImportModal(true);
    };

    const handleCloseImportModal = () => {
        setOpenImportModal(false);
        setCsvFile(null);
    };

    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            console.log('Arquivo selecionado:', acceptedFiles[0]);
            setCsvFile(acceptedFiles[0]);
        }
    };

    const handleImportUsers = async () => {
        if (!csvFile) {
            toast.error('Por favor, selecione um arquivo CSV.');
            return;
        }

        const formData = new FormData();
        formData.append('file', csvFile);

        formData.append(
            'options',
            JSON.stringify({
                replace_contacts: replaceContacts,
                link_chats: linkChats,
                ignore_errors: ignoreErrors
            })
        );

        console.log('FormData para envio:', formData);
        for (let pair of formData.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
        }

        try {
            const clienteId = uuidv4();
            const response = await v2_actionImportarContatos(clienteId, formData);

            console.log('Resposta da API:', response);

            if (response.status === 200) {
                toast.success('Usuários importados com sucesso!');
                handleCloseImportModal();
            }
        } catch (error) {
            console.error('Erro ao importar usuários:', error);
            if (error.response) {
                console.error('Detalhes do erro:', error.response.data);
            }
            toast.error('Erro ao importar usuários.');
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: '.csv'
    });


    // Definir o estilo do dropzone com base no estado
    const currentDropzoneStyle = csvFile
        ? { ...dropzoneStyle, ...dropzoneSelectedStyle }
        : isDragActive
            ? { ...dropzoneStyle, ...dropzoneActiveStyle }
            : dropzoneStyle;

    async function handleClientesHub() {
        try {
          //  setLoading(true)
           let query = `?provedor=${user.provedor}`;
            const response = await v2_actionListarClientesHub(query);
            setListaClientes(response.data.data);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error('Erro ao buscar clientes:', error);
        }
    }

    // async function handleBuscarClientes() {
    //   try {
    //     setLoading(true)
    //     const response = await todosUsuarios({ provedor: user.provedor });
    //     const sortedClients = response.data.clientes.sort((a, b) => {
    //       if (a.isHub && !b.isHub) return -1;
    //       if (!a.isHub && b.isHub) return 1;
    //       return 0;
    //     });
    //     setListaClientes(sortedClients);
    //     setLoading(false)
    //   } catch (error) {
    //     setLoading(false)
    //     console.error('Erro ao buscar clientes:', error);
    //   }
    // }



    const buscarListaServidores = async () => {
        try {
            const res = await actionListaServidores();
            setListaServidores(res.data.data);
        } catch (error) { }
    };

    useEffect(() => {
        handleClientesHub()
        buscarListaServidores();
    }, []);


    const handleDeleteCacheRedis = async (sessao) => {
        try {
            const response = await actionDeletarCacheRedis(sessao);
            if (response.status === 200) {
                alert('Cache deletado com sucesso!');
            }
        } catch (error) {
            alert('Erro ao deletar cache!');
        }
    };


    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const handleDeleteUser = (userId) => {
        dispatch(deleteUser(userId));
    };

    const handleStartSession = (pod, sessao, token) => {
        iniciarSessaoWhatsapp(pod, sessao, token)
    };

    const handleCloseSession = (pod, sessao, token) => {
        fecharSessaoWhatsapp(pod, sessao, token)
            .then(() => alert('Sessão Fechada, aguarde alguns segundos pra Iniciar'))
            .catch(() => alert('Erro ao iniciar sessão'));
    };

    const handleChangeSentinelaStatus = async (clienteId, event) => {
        try {
            await actionEditarCliente(clienteId, {
                scope: {
                    status: event.target.checked
                },
                action: 'editar_status_sentinela'
            });
            handleClientesHub()
            toast.success(`Status da sentinela ${event.target.checked ? 'ativado' : 'desativado'} com sucesso!`);
        } catch (error) {
            toast.error('Erro ao alterar status da sentinela');
        }
    };

    const handleBlockClient = async (clienteId, isBlock) => {
        console.log('Tentando bloquear cliente:', clienteId, 'Novo estado:', isBlock);
        try {
            const data = {
                scope: {
                    action: 'bloquear_cliente',
                    isBlock: isBlock
                }
            };

            const response = await actionAdminActionCliente(clienteId, data);
            console.log('Resposta da API:', response);

            if (response.status === 200) {
                handleClientesHub()
                toast.success(`Cliente ${isBlock ? 'bloqueado' : 'desbloqueado'} com sucesso!`);
            } else {
                toast.error(`Erro ao ${isBlock ? 'bloquear' : 'desbloquear'} cliente`);
            }
        } catch (error) {
            toast.error(`Erro ao ${isBlock ? 'bloquear' : 'desbloquear'} cliente: ${error.message}`);
        }
    };


    const handleChangeFase = async (clienteId, newValue) => {
        try {
            const data = {
                scope: {
                    action: 'editar_fase',
                    fase: newValue
                }
            };

            const response = await actionAdminActionCliente(clienteId, data);
            console.log('Resposta da API:', response);

            if (response.status === 200) {
                handleClientesHub()
                toast.success(`Cliente alterado com sucesso!`);
            } else {
                toast.error(`Erro ao alterar cliente`);
            }
        } catch (error) {
            toast.error(`Erro ao alterar cliente: ${error.message}`);
        }
    };

    const normalizeInput = (input) => {
        return input
            .toLowerCase() // Converte para letras minúsculas
            .normalize('NFD') // Normaliza o texto para decompor caracteres acentuados
            .replace(/[\u0300-\u036f]/g, '') // Remove os acentos

    };

    return (
        <Page title="Lais - Clientes">
            <Container maxWidth={'xl'}>
                <HeaderBreadcrumbs
                    heading="Lista de Clientes"
                    links={[
                        { name: 'Dashboard', href: PATH_DASHBOARD.root },
                        { name: 'Lais', href: PATH_DASHBOARD.plataforma.root },
                        { name: 'Lista de Clientes' }
                    ]}
                    action={
                        <>
                            <Button onClick={handleClickOpen} variant="contained" sx={{ mr: 2 }}>
                                Criar Cliente
                            </Button>
                            {/* <Button onClick={handleOpenImportModal} variant="contained">
                                Importar Usuários
                            </Button> */}
                        </>
                    }
                />
                <ToastContainer />

                {loading ? (
                    <Box sx={{ flex: 1, width: '100%', height: '80vh', alignItems: 'center', justifyContent: 'center' }}>
                        <LoadingScreen />
                    </Box>
                ) : (
                    <Card>
                        <UserListToolbar
                            numSelected={selected.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            listaServidores={listaServidores}
                            pod={pod}
                            changePod={(event) => setPod(event.target.value)}
                        />

                        <Dialog open={open} onClose={handleClose}>
                            <DialogTitle>Criar Novo Cliente</DialogTitle>
                            <DialogContent>
                                <DialogContentText>Insira as informações do novo Cliente que você deseja criar.</DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Nome do Cliente"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    value={newUserName}
                                    onChange={(e) => setNewUserName(e.target.value)}
                                />
                                <TextField
                                    margin="dense"
                                    label="Cliente"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    value={newUser}
                                    onChange={(e) => setNewUser(normalizeInput(e.target.value))} // Aplica a normalização
                                />
                                <TextField
                                    margin="dense"
                                    label="Número do Cliente"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    value={newUserNumber}
                                    onChange={(e) => setNewUserNumber(e.target.value)}
                                />
                                <TextField
                                    select
                                    margin="dense"
                                    label="Servidor (POD)"
                                    fullWidth
                                    variant="standard"
                                    value={newUserServer}
                                    onChange={(e) => setNewUserServer(e.target.value)}
                                    SelectProps={{
                                        native: true
                                    }}
                                >
                                    <option value=""></option>
                                    {listaServidores && listaServidores.length > 0 ? (
                                        listaServidores.map((server) => (
                                            <option key={server._id} value={server.pod}>
                                                {server.pod}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="" disabled>
                                            Nenhum servidor disponível
                                        </option>
                                    )}
                                </TextField>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Cancelar</Button>
                                <Button onClick={handleCreateUser} variant="contained">
                                    Criar
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={openImportModal} onClose={handleCloseImportModal}>
                            <DialogTitle>Importar Usuários</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Selecione o arquivo CSV contendo os dados dos usuários que você deseja importar.
                                </DialogContentText>
                                <div {...getRootProps()} style={currentDropzoneStyle}>
                                    <input {...getInputProps()} />
                                    {isDragActive ? (
                                        <p>Solte o arquivo aqui...</p>
                                    ) : csvFile ? (
                                        <p>Arquivo selecionado: {csvFile.name}</p>
                                    ) : (
                                        <p>Arraste um arquivo ou clique aqui para selecionar!</p>
                                    )}
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseImportModal}>Cancelar</Button>
                                <Button onClick={handleImportUsers} variant="contained" disabled={!csvFile}>
                                    Importar
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 400 }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            {TABLE_HEAD.map((column) => (
                                                <TableCell key={column.id} align={column.alignRight ? 'right' : 'left'}>
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>

                            {filteredUsers
                                .map((hub) => {
                                    return (
                                        <Accordion key={`hub-${hub._id}`}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={`panel-${hub._id}-content`}
                                                id={`panel-${hub._id}-header`}
                                            >
                                                <Typography>{hub.hub}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <TableContainer>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                {TABLE_BODY.map((column) => (
                                                                    <TableCell key={column.id} align={column.alignRight ? 'right' : 'left'}>
                                                                        {column.label}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {hub.items
                                                                .map((cliente) => (
                                                                    <TableRow key={cliente._id}>

                                                                        <TableCell align="left">{cliente.nome}</TableCell>
                                                                        <TableCell align="left">{cliente.sessao}</TableCell>
                                                                        <TableCell align="left">{cliente.pod}</TableCell>
                                                                        <TableCell align="left">{cliente.numero}</TableCell>
                                                                        <TableCell align="left">
                                                                            <Switch
                                                                                onChange={(event) => handleChangeSentinelaStatus(cliente._id, event)}
                                                                                checked={cliente.sentinela}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            <Switch
                                                                                onChange={(event) => handleBlockClient(cliente._id, event.target.checked)}
                                                                                checked={cliente.isBlock || false}
                                                                            />
                                                                        </TableCell>

                                                                        <TableCell >
                                                                            <Stack direction={"row"} justifyContent={"center"}>
                                                                            <Select
                                                                                fullWidth
                                                                                value={cliente.fase}
                                                                                size={"small"}
                                                                                onChange={e => handleChangeFase(cliente._id, e.target.value)}
                                                                            >
                                                                                <MenuItem value={"onboarding"}>Onboarding</MenuItem>
                                                                                <MenuItem value={"ativo"}>Ativo</MenuItem>
                                                                                <MenuItem value={"inativo"}>Inativo</MenuItem>
                                                                                <MenuItem value={"dev"}>Dev</MenuItem>
                                                                                <MenuItem value={"demo"}>Demonstração</MenuItem>
                                                                                <MenuItem value={"suporte"}>Suporte</MenuItem>

                                                                            </Select>
                                                                            </Stack>

                                                       
                                                                        </TableCell>

                                                                        <TableCell align="right">
                                                                            <IconButton onClick={() => handleDeleteCacheRedis(cliente.sessao)}>
                                                                                <Icon icon={'ant-design:clear-outlined'} width={20} height={20} />
                                                                            </IconButton>
                                                                            <ClientesMenuActions
                                                                                onStartSession={() =>
                                                                                    handleStartSession(cliente.pod, cliente.sessao, cliente.token)
                                                                                }
                                                                                onCloseSession={() =>
                                                                                    handleCloseSession(cliente.pod, cliente.sessao, cliente.token)
                                                                                }
                                                                            />
                                                                            <UserMoreMenu
                                                                                onDelete={() => handleDeleteUser(cliente._id)}
                                                                                userName={cliente._id}
                                                                                onBlock={(isBlock) => handleBlockClient(cliente._id, isBlock)}
                                                                            />
                                                                        </TableCell>

                                                                    </TableRow>
                                                                ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                })}


                        </Scrollbar>
                    </Card>
                )}



            </Container>
        </Page>
    );
}
